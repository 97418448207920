
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import {dateConvert} from "@/core/filters/datatime";

import ItemUpdate from "@/components/hotel/ActionsUpdate.vue";
import ItemAddNew from "@/components/hotel/ActionsAddNew.vue";
import {commonf} from "@/core/filters/common";
import ActionSpecialDays from "@/views/apps/sys/hotels/ActionSpecialDays.vue";

export default defineComponent({
    name: "overview",
    components: {
        ItemAddNew,
        ItemUpdate,
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Otel Aksiyon", ["Otel"]);
        });

        const route = useRoute();
        const id = route.params.id;
        const payload = {
            ID: id,
        }
        //console.log(payload);
        store.dispatch(Actions.HOTEL_DETAILS, payload);
        const myDetails = computed(() => {
            return store.getters.hotelDetails;
        });

        const payload3 = {
            HotelID: id,
            is_active: "active",
        }


      //  store.dispatch(Actions.HOTEL_ACTIONS_LIST, payload3);
      const arrHotelActionList = ref([]);
      store.dispatch(Actions.HOTEL_ACTIONS_LIST,  payload3).then((prmData) => {
        arrHotelActionList.value=prmData.data;
        setTimeout(getActionFinish, 500);
      })
       /* const myList = computed(() => {

            return store.getters.hotelActionsList;
        });*/




        const updateActive = (ID, Active) => {
            const payload = {
                ID: ID,
                HotelID: id,
                is_active: (Active == 'active') ? "passive" : "active",
            }
            store.dispatch(Actions.HOTEL_ACTIONS_EDIT, payload)
        }

        const deleteItem = (ID) => {
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    const payload = {
                        ID: ID,
                        HotelID: id,
                    }
                    store.dispatch(Actions.HOTEL_ACTIONS_DELETE, payload)
                        .then(() => {
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getHotelActionsErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                }
            });

        }

        let itemID = ref<number>(0);

        function setItems(ID) {
            itemID.value = ID;
        }

        function orderItem(prmIndex, prmAction) {
            //console.log("orderItem index: "+prmIndex+" prmAction: "+prmAction);
            const payload = {
                ID: arrHotelActionList.value[prmIndex].ID,
                Action: prmAction,
                HotelID: arrHotelActionList.value[prmIndex].HotelID,
            }
            console.log("orderItem payload: "+JSON.stringify(payload));
            store.dispatch(Actions.HOTEL_ACTIONS_ORDER, payload);

        }


      function getActionFinish() {
        for(let i=0; i<arrHotelActionList.value.length; i++){
          let each = arrHotelActionList.value[i];
          const  prmID = each.ID;
          const elGroup = document.getElementById("trAction_"+prmID) as HTMLElement;
          console.log("getActionFinish elGroup " + prmID);
          if(each.FinishDate<dateConvert.method.getTodayYMD()){
            if(elGroup!==null){
              elGroup.style.setProperty("display", "none", "important");
              elGroup.style.setProperty("background-color", "#FFF0F5", "important");
            }
          }

        }
      }

      function showHideArchiveActions() {
          console.log("showHideArchiveActions " +JSON.stringify(arrHotelActionList.value));
        for(let i=0; i<arrHotelActionList.value.length; i++){
          let each = arrHotelActionList.value[i];
          const  prmID = each.ID;
          const elGroup = document.getElementById("trAction_"+prmID) as HTMLElement;
          const checkboxid = document.getElementById("recallArchiveActions")  as HTMLInputElement;
          if(checkboxid.checked){
            elGroup.style.setProperty("display", "", "important");
          }else{
            getActionFinish();
          }

        }


      }


        return {
            id,
            myDetails,
           // myList,
            dateConvert,
            itemID,
            setItems,
            updateActive,
            deleteItem,
            commonf,
            orderItem,
          showHideArchiveActions,
          arrHotelActionList,
        };
    },
});
